<template>
  <v-container>
    <v-card class="pa-2 rounded-t-xl rounded-b-xl">
      <span class="grey--text subtitle-2">#{{data.cardNumber}}</span>
      <div class="d-flex align-center justify-center flex-column">
        <v-img
          src="../../assets/hunter.png"
          class="rounded-circle"
          :width="64"
          :height="64"
        ></v-img>
        <span class="title-1 font-weight-bold mt-2">{{ data.firstName }} {{ data.lastName }}</span>
        <span class="subtitle-2">Sekcija: {{data.section.name}}</span>
        <span class="subtitle-2 font-weight-medium green--text green--2">Aktivan</span>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style></style>
