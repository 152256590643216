<template>
  <div>
    <v-dialog
      v-model="inviteModal"
      width="600px">
      <v-card class="pa-4">
        <v-card-title>Pozovi člana kao korisnika</v-card-title>
        <v-card-text>

        <v-form class="mt-4" @submit.prevent="inviteMember">
          <v-select :items="roles" label="Uloga" v-model="selectedRole"></v-select>
          <v-card-actions class="pa-0 d-flex justify-end">
            <v-btn type="submit" color="primary">Pozovi</v-btn>
            <v-btn color="gray" @click="inviteModal = false">Odustani</v-btn>
          </v-card-actions>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editEnabled"
        width="1080px"
    >
      <v-card class="pa-4">
        <div class="d-flex justify-space-between">

          <v-card-title class="title pa-0">Izmijeni člana</v-card-title>
          <v-btn icon @click="editEnabled = false"><v-icon>mdi-close</v-icon></v-btn>
        </div>
        <div class="d-flex justify-center">
          <v-img src="../../assets/avatar.png" max-height="96px" max-width="96px" class="text-center"></v-img>
        </div>
        <v-form class="mt-4">
          <v-row>
            <v-col cols="6">
              <h3 class="mb-4">Osobne informacije</h3>
              <v-text-field
                  outlined
                  dense
                  label="Ime"
                  v-model="memberForm.firstName"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Prezime"
                  v-model="memberForm.lastName"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Jedinstveni matični broj građana (JMBG)"
                  v-model="memberForm.jmbg"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Ime oca"
                  v-model="memberForm.fatherName"
              ></v-text-field>

              <v-text-field
                  outlined
                  dense
                  label="Mjesto rođenja"
                  v-model="memberForm.birthPlace"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Datum rođenja"
                  v-model="memberForm.dob"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Address"
                  v-model="memberForm.address"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Mjesto stanovanja"
                  v-model="memberForm.livingPlace"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Broj telefona"
                  v-model="memberForm.phoneNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <h3 class="mb-4">Lovne informacije</h3>
              <v-text-field
                  outlined
                  dense
                  label="Broj iskaznice"
                  v-model="memberForm.cardNumber"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="Broj knjige"
                  v-model="memberForm.bookNumber"
              ></v-text-field>
              <v-text-field
                  outlined
                  dense
                  label="ID broj (legacy)"
                  v-model="memberForm.idNumber"
              ></v-text-field>
              <v-select
                  outlined
                  dense
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  label="Status"
                  v-model="memberForm.memberStatus"
              ></v-select>
              <v-select
                  outlined
                  dense
                  :items="sections"
                  item-text="name"
                  item-value="id"
                  label="Sekcija"
                  v-model="memberForm.sectionId"
              ></v-select>
              <v-switch v-model="memberForm.honoredMember" label="Počasni član"></v-switch>
              <v-text-field v-model="memberForm.emailAddress" label="E-Mail adresa"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pa-0">
          <v-col class="text-right pa-0">

            <v-btn @click="saveMember" color="primary" depressed>Spremi</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="pa-4">
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-img class="rounded" src="../../assets/avatar.png" :max-width="64" :max-height="64"></v-img>
          <div class="d-flex flex-column">

            <v-card-title class="ml-2">
        <span>
          {{ member.firstName }} {{ member.lastName }}
        </span>
            </v-card-title>
            <v-card-subtitle class="ml-2">
              Broj iskaznice: {{ member.cardNumber }} | Broj knjige: {{ member.bookNumber }}
            </v-card-subtitle>
          </div>
        </div>
        <div>
          <v-card-actions v-if="user.role !== 'viewer'">
            <v-btn icon @click="inviteModal = true" v-if="user.role === 'admin'">
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
            <v-btn icon @click="editMember">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon disabled>
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>

      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-row>
          <v-col cols="12">
            <span class=" subtitle-1 font-weight-bold">Lovne informacije</span>
          </v-col>
        </v-row>
        <v-row v-if="member.section">
          <v-col cols="6">
            <span class="subtitle-2">Sekcija</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.section.name }}
          </v-col>
        </v-row>
        <v-row v-if="member.status">
          <v-col cols="6">
            <span class="subtitle-2">Status</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            <span :style="{'color': mmbStatuses[member.status.name].color}">{{ mmbStatuses[member.status.name].text }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <span class="subtitle-2">Počasni član</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            <v-icon :color="member.honoredMember ? '#2BDA49' : '#da2b2b'">{{member.honoredMember ? 'mdi-check' : 'mdi-close'}}</v-icon>
          </v-col>
        </v-row>
        <v-row v-if="member.idNumber">
          <v-col cols="6">
            <span class="subtitle-2">ID broj</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.idNumber }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class=" subtitle-1 font-weight-bold">Osobne informacije</span>
          </v-col>
        </v-row>
        <v-row v-if="member.jmbg">
          <v-col cols="6">
            <span class="subtitle-2">JMBG</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.jmbg }}
          </v-col>
        </v-row>
        <v-row v-if="member.fatherName">
          <v-col cols="6">
            <span class="subtitle-2">Ime oca</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.fatherName }}
          </v-col>
        </v-row>

        <v-row v-if="member.birthPlace">
          <v-col cols="6">
            <span class="subtitle-2">Mjesto rođenja</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.birthPlace }}
          </v-col>
        </v-row>
        <v-row v-if="member.dob">
          <v-col cols="6">
            <span class="subtitle-2">Datum rođenja</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.dob }}
          </v-col>
        </v-row>
        <v-row v-if="member.address">
          <v-col cols="6">
            <span class="subtitle-2">Adresa stanovanja</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.address }}, {{ member.livingPlace }}
          </v-col>
        </v-row>
        <v-row v-if="member.nationality">
          <v-col cols="6">
            <span class="subtitle-2">Nacionalnost</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.nationality }}
          </v-col>
        </v-row>
        <v-row v-if="member.phoneNumber">
          <v-col cols="6">
            <span class="subtitle-2">Broj telefona</span>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6" class="ml-n1 text-right">
            {{ member.phoneNumber }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-0 mt-8">
        <v-col class="text-right pa-0">

          <v-btn color="gray" depressed @click="$emit('onClose')">Zatvori</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  props: ['member'],
  computed: {
    ...mapGetters({'user': 'user/user', 'sections': 'section/sections', 'statuses': 'members/statuses', 'query': "members/query"})
  },
  methods: {
    ...mapActions({
      'inviteUser': 'user/invite',
      'updateMember': 'members/updateMember',
      'get': 'members/get',
      'addAlert': 'global/addAlert'
    }),
    editMember() {
      this.memberForm = Object.assign({}, this.member)
      this.editEnabled = true;
    },
    inviteMember() {
      const body = {
        email_address: this.member.emailAddress,
        role: this.selectedRole
      };

      this.inviteUser(body).then(() => {
        this.addAlert({
          type: 'success',
          message: `Član ${this.member.firstName} ${this.member.lastName} uspješno pozvan kao korisnik.`
        });
      }).catch((err) => {
        this.addAlert({
          type: 'error',
          message: `Error: ${err.response.data.error}`
        });
      })
    },
    saveMember() {
      delete this.memberForm.section
      delete this.memberForm.status
      this.memberForm.updatedAt = new Date().toISOString()
      this.updateMember(this.memberForm).then(() => {
        this.editEnabled = false;
        this.addAlert({
          type: 'success',
          message: `Član ${this.member.firstName} ${this.member.lastName} uspješno ažuriran.`
        });
        this.get(this.query);
      }).catch(err => {
        this.addAlert({
          type: 'error',
          message: `Error: ${err.response.data.error}`
        });
        console.log(err.response.data);
      });
    }
  },
  data: () => ({
    editEnabled: false,
    inviteModal: false,
    roles: [{
      text: "Viewer",
      value: "viewer",
    },
    {
      text: "Editor",
      value: "editor",
    },
    {
      text: "Admin",
      value: "Admin"
    }],
    selectedRole: "viewer",
    memberForm: {},
    mmbStatuses: {
      "frozen": {
        "text": "Zamrznut",
        "color": "#1DD3FF"
      },
      "paid": {
        "text": "Aktivan",
        "color": "#2BDA49"
      },
      "not_paid": {
        "text": "Neplaćena članarina",
        "color": "#da2b2b"
      },
      "abandoned": {
        "text": "Ispisan",
        "color": "#da7d2b"
      },
      "deceased": {
        "text": "Preminuo",
        "color": "#6b6b6b"
      },
      "not_active": {
        "text": "Neaktivan",
        "color": "#da2b2b",
        "icon": "mdi-account-remove"
      }
    }
  })
}
</script>

<style scoped>

</style>
