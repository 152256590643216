<template>
  <div>

  <v-row class="px-2">
    <v-col
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        class="px-2 pb-4 pt-0"
        v-bind:key="item.id"
        @click="selectItem(item)"
        v-for="item in data">
      <GridItem :data="item"></GridItem>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
        <v-pagination v-model="pagingData" class="my-4" :length="pagingData.totalPages"></v-pagination>
    </v-col>
  </v-row>
  </div>
</template>
<script>
import GridItem from './GridItem';

export default {
  name: 'GridView',
  components: {
    GridItem,
  },
  props: ['data', 'pagingData'],
  methods: {
    selectItem(item) {
      this.$emit('onSelectMember', item)
    }
  }
  
};
</script>

<style scoped>

</style>
