var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticStyle:{"border":"1px solid #dedede"},attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":12,"options":_vm.options,"loading":_vm.loading,"footer-props":{
    itemsPerPageOptions: [12]},"server-items-length":_vm.pagingData.totalItems},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.selectItem},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName)+" "+_vm._s(item.lastName))])]}},{key:"item.status.name",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.mmbStatuses[item.status.name].color}},[_vm._v(_vm._s(_vm.mmbStatuses[item.status.name].icon))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.mmbStatuses[item.status.name].text))])]}},{key:"item.honoredMember",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.honoredMember ? '#2BDA49' : '#da2b2b'}},[_vm._v(_vm._s(item.honoredMember ? 'mdi-check' : 'mdi-close'))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }