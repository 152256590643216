<template>
<v-card class="pa-4">
<div class="d-flex justify-space-between">

  <v-card-title class="title pa-0">Dodaj člana</v-card-title>
  <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn>
</div>
<div class="d-flex justify-center">
  <v-img src="../../assets/avatar.png" max-height="96px" max-width="96px" class="text-center"></v-img>
</div>
<v-form class="mt-4">
  <v-row>
    <v-col cols="6">
      <h3 class="mb-4">Osobne informacije</h3>
      <v-text-field
          outlined
          dense
          label="Ime"
          v-model="memberForm.firstName"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="Prezime"
          v-model="memberForm.lastName"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="Jedinstveni matični broj građana (JMBG)"
          v-model="memberForm.jmbg"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="Ime oca"
          v-model="memberForm.fatherName"
      ></v-text-field>

      <v-text-field
          outlined
          dense
          label="Mjesto rođenja"
          v-model="memberForm.birthPlace"
      ></v-text-field>
      <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="memberForm.dob"
      transition="scale-transition"
      offset-y
      min-width="290px"
      >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="memberForm.dob"
            label="Datum rođenja"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="memberForm.dob"
          no-title
          scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.menu.save(memberForm.dob)"
        >
          OK
        </v-btn>
      </v-date-picker>
      </v-menu>
      <v-text-field
          outlined
          dense
          label="Address"
          v-model="memberForm.address"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="Mjesto stanovanja"
          v-model="memberForm.livingPlace"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="Broj telefona"
          v-model="memberForm.phoneNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <h3 class="mb-4">Lovne informacije</h3>
      <v-text-field
          outlined
          dense
          label="Broj iskaznice"
          v-model="memberForm.cardNumber"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="Broj knjige"
          v-model="memberForm.bookNumber"
      ></v-text-field>
      <v-text-field
          outlined
          dense
          label="ID broj (legacy)"
          v-model="memberForm.idNumber"
      ></v-text-field>
      <v-select
          outlined
          dense
          :items="statuses"
          item-text="name"
          item-value="id"
          label="Status"
          v-model="memberForm.memberStatus"
      ></v-select>
      <v-select
          outlined
          dense
          :items="sections"
          item-text="name"
          item-value="id"
          label="Sekcija"
          v-model="memberForm.sectionId"
      ></v-select>
      <v-text-field
        outlined
        dense
        label="E-Mail adresa"
        v-model="memberForm.emailAddress">

      </v-text-field>
      <v-switch v-model="memberForm.honoredMember" label="Počasni član"></v-switch>

    </v-col>
  </v-row>
</v-form>
<v-card-actions class="pa-0">
  <v-col class="text-right pa-0">

    <v-btn @click="$emit('onSave', memberForm)" color="primary" depressed>Spremi</v-btn>
  </v-col>
</v-card-actions>
</v-card>
</template>
<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'AddMember',
    computed: {
      ...mapGetters({'sections': 'section/sections', 'statuses': 'members/statuses'})
    },
    data: () => ({
      menu: false,
      memberForm: {}
    }),
  }
</script>
