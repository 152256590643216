<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :items-per-page="12"
    :options.sync="options"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [12]}
    "
    @click:row="selectItem"
    :server-items-length="pagingData.totalItems"
    style="border: 1px solid #dedede"
  >
    <template v-slot:[`item.full_name`]="{ item }">
      <span>{{ item.firstName }} {{ item.lastName }}</span>
    </template>
    <template v-slot:[`item.status.name`]="{ item }">
      <v-icon :color="mmbStatuses[item.status.name].color">{{mmbStatuses[item.status.name].icon}}</v-icon><span class="ml-2">{{ mmbStatuses[item.status.name].text }}</span>
    </template>
    <template v-slot:[`item.honoredMember`]="{ item }">
      <v-icon :color="item.honoredMember ? '#2BDA49' : '#da2b2b'">{{item.honoredMember ? 'mdi-check' : 'mdi-close'}}</v-icon>
    </template>

  </v-data-table>
</template>
<script>
  export default {
    props: ['data', 'pagingData', 'loading'],
    data () {
      return {
        options: {},
        initializing: false,
        mmbStatuses: {
          "frozen": {
            "text": "Zamrznut",
            "color": "#1DD3FF",
            "icon": "mdi-account-lock"
          },
          "paid": {
            "text": "Aktivan",
            "color": "#2BDA49",
            "icon": "mdi-account-check"
          },
          "not_paid": {
            "text": "Neplaćena članarina",
            "color": "#da2b2b",
            "icon": "mdi-account-minus"
          },
          "abandoned": {
            "text": "Ispisan",
            "color": "#da7d2b",
            "icon": "mdi-account-remove"
          },
          "deceased": {
            "text": "Preminuo",
            "color": "#6b6b6b",
            "icon": "mdi-shield-cross"
          },
          "not_active": {
            "text": "Neaktivan",
            "color": "#da2b2b",
            "icon": "mdi-account-remove"
          }
        },
        headers: [
          {
            text: 'ID ',
            align: 'start',
            value: 'id',
          },
          { text: 'Ime', value: 'firstName', align: 'start' },
          { text: 'Prezime', value: 'lastName', align: 'start' },
          { text: 'Otac', value: 'fatherName' },
          { text: 'Počasni član', value: 'honoredMember' },
          { text: 'Sekcija', value: 'section.name', sortable: false },
          { text: 'Status', value: 'status.name', sortable: false },
          { text: 'Broj iskaznice', value: 'cardNumber' },
        ],
      }
    },
    methods: {
      selectItem(item) {
        this.$emit('onSelectMember', item)
      }
    },
    watch: {
      options: {
        handler(val) {
          if (!this.initializing) {
            this.initializing = true;
          } else {
            this.$emit('onPaginate', val)
          }
        },
        deep: true
      }
    }
  }
</script>
