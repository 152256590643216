<template>
  <v-container fluid>
    <v-dialog
    v-model="memberOpened"
    width="1080px"
    >
      <Member :member="selectedMember" @onClose="memberOpened = false" />
    </v-dialog>
    <v-dialog
        v-model="addOpened"
        width="1080px"
    >
      <AddMember @onSave="addMember" @onClose="addOpened = false" />
    </v-dialog>
    <v-row class="fill-height">
      <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="px-0 overflow-y-auto">
        <Filters @onApply="applyFilters" />
      </v-col>
      <v-divider
        class="d-none d-md-flex"
        vertical/>
      <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="ml-n3 py-0 pr-0 fill-height">
        <v-row justify="end" align="center">
          <v-btn
            @click="setView('grid')"
            icon
            :color="viewType === 'grid' ? 'primary' : 'grey'"
          >
            <v-icon>mdi-dots-grid</v-icon>
          </v-btn>
          <v-btn
            @click="setView('table')"
            icon
            :color="viewType === 'table' ? 'primary' : 'grey'"
          >
            <v-icon>mdi-table-large</v-icon>
          </v-btn>
          <v-btn
              class="ml-4"
            @click="addOpened = true"
            icon
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
        <GridView v-if="viewType === 'grid'" :data="members" :pagingData="pagingData" @onNext="nextPage" @onPrev="prevPage" @onSelectMember="selectMember" />
        <TableView v-if="viewType === 'table'" :data="members" :pagingData="pagingData" :loading="loading" @onPaginate="handlePaginate" @onSelectMember="selectMember"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GridView from "@/components/members/GridView";
import TableView from "@/components/members/TableView";
import Filters from "@/components/members/Filters";
import Member from "@/components/members/Member";
import AddMember from "@/components/members/AddMember";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Members",
  components: {
    GridView,
    TableView,
    Filters,
    Member,
    AddMember
  },
  data: () => ({
    viewType: "table",
    memberOpened: false,
    addOpened: false
  }),
  computed: {
    ...mapGetters({
      "members": "members/members",
      "pagingData": "members/pagingData",
      "loading": "members/loadingMembers",
      "selectedMember": "members/selectedMember",
      "token": "user/token",
    })
  },

  mounted() {
    if (!this.token) {
      this.$router.replace("/login")
    } else {
      this.getSelf().catch(err => {
        if (err.response.status === 401) {
          this.router.replace("/login");
        }
      });
    }
    this.getMembers({
      page: 1
    });
    this.getMemberStatuses();
    this.getSections();
  },
  methods: {
    ...mapActions({
      "addAlert": "global/addAlert",
      "getMembers": "members/get",
      "selectSpecificMember": "members/select",
      "getMemberStatuses": "members/getStatuses",
      "getSections": "section/get",
      "addNewMember": "members/addMember",
      "getSelf": "user/getSelf"
    }),
    selectMember(member) {
      this.selectSpecificMember(member);
      this.memberOpened = true;
    },
    addMember(member) {
      if (!member.honoredMember) {
        member.honoredMember = false;
      }
      this.addNewMember(member).then(() => {
        this.addOpened = false;
        this.addAlert({
          type: 'success',
          message: `Član ${member.firstName} ${member.lastName} uspješno dodan.`
        });
      }).catch(err => {
        console.log(err.response.data);
      });
    },
    nextPage() {

    },
    prevPage() {

    },
    handlePaginate(val) {
      let { sortBy, sortDesc, page } = val;
      let query = {
        ...this.$store.getters["members/query"],
        page: page
      };
      let orders = [];
      if (sortBy.length > 0){
        sortBy.map((sort, i) => {
          let specificOrder = []
          specificOrder.push(sort);
          specificOrder.push(sortDesc[i] ? "DESC" : "ASC")
          orders.push(specificOrder)
        })
      }
      if (orders.length > 0) {
        query.order = `[${JSON.stringify(orders)}]`
      }
      this.getMembers(query);
    },
    applyFilters(filters) {
      console.log(filters);
    },
    setView(view) {
      this.viewType = view;
    },
  },
};
</script>

<style></style>
