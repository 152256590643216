<template>
  <v-container fluid>
    <p class="title-1 font-weight-medium mb-4">Filteri</p>
    <v-form @submit.prevent="applyFilters">

    <v-row dense>
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          v-model="filters.firstName"
          placeholder="Ime"
          class="rounded-t-lg rounded-b-lg"
          append-icon="mdi-close"
          @click:append="clearField('firstName')"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
            outlined
            dense
            v-model="filters.lastName"
            placeholder="Prezime"
            class="rounded-t-lg rounded-b-lg"
            append-icon="mdi-close"
            @click:append="clearField('lastName')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-select
    placeholder="Sekcija"
    outlined
    dense
    :items="sections"
    item-text="name"
    item-value="id"
    v-model="filters.sectionId"
    append-icon="mdi-close"
    @click:append="clearField('sectionId')"
    class="rounded-t-lg rounded-b-lg"></v-select>
    <v-text-field
      outlined
      dense
      v-model="filters.cardNumber"
      placeholder="Broj iskaznice"
      type="text"
      append-icon="mdi-close"
      @click:append="clearField('cardNumber')"
      class="rounded-t-lg rounded-b-lg"
    ></v-text-field>
    <v-select
        placeholder="Status"
        outlined
        dense
        :items="statuses"
        item-text="name"
        item-value="id"
        append-icon="mdi-close"
        @click:append="clearField('memberStatus')"
        v-model="filters.memberStatus"
        class="rounded-t-lg rounded-b-lg"></v-select>
    <v-btn color="primary" block type="submit" @click="applyFilters">Primijeni</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// TODO: stao sam na toggle filterima
export default {
  computed: {
    ...mapGetters({"sections": "section/sections", "statuses": "members/statuses"})
  },
  data: () => ({
    filters: {
      firstName: null,
      lastName: null,
      cardNumber: null,
      sectionId: null,
      memberStatus: null,
    },
  }),
  methods: {
    ...mapActions({"getMembers": "members/get"}),
    clearField(field) {
      this.filters[field] = null;
    },
    applyFilters() {
      var parameterizedFilters = {}
      if (this.filters.firstName) {
        parameterizedFilters["firstName"] = {
          "like": this.filters.firstName
        }
      }
      if (this.filters.lastName) {
        parameterizedFilters["lastName"] = {
          "like": this.filters.lastName
        }
      }
      if (this.filters.cardNumber) {
        parameterizedFilters["cardNumber"] = {
          "like": this.filters.cardNumber
        }
      }
      if (this.filters.memberStatus) {
        parameterizedFilters["memberStatus"] = this.filters.memberStatus
      }
      if (this.filters.sectionId) {
        parameterizedFilters["sectionId"] = this.filters.sectionId
      }
      this.getMembers({
        page: 1,
        where: parameterizedFilters});
    }
  }
};
</script>

<style></style>
